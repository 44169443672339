<template>
  <v-container fluid class="mt-4">
    <v-row
      class="d-flex justify-center"
      v-if="session && session.challenge.plan[currentDay].type == 'trainings'"
    >
      <v-col cols="12" lg="8" md="9" sm="10">
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            class="rounded"
            :src="`https://player.vimeo.com/video/${session.challenge.plan[currentDay].trainings_json[trainingIndex].vimeo_id}?h=401fac24f8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title=""
          ></iframe>
        </div>
        <h4 class="text-typo text-center mt-2">
          {{
            `${session.challenge.plan[currentDay].trainings_json[trainingIndex].name}`
          }}
        </h4>
      </v-col>
    </v-row>

    <v-row
      class="d-flex justify-center"
      v-if="session.challenge.plan[currentDay].type != 'trainings'"
    >
      <v-col cols="12" class="d-flex justify-center">
        <v-img
          src="@/assets/img/setupwelcome.png"
          contain
          :style="'max-height: 400px !important;'"
        >
        </v-img>
      </v-col>
    </v-row>
    <v-row v-if="session.challenge.plan[currentDay].type != 'trainings'">
      <v-col cols="12" class="d-flex justify-center">
        <h1 class="text-center text-typo">Dziś odpoczywamy</h1>
      </v-col>
    </v-row>
    <v-divider class="ma-2"></v-divider>
    <v-row class="d-flex justify-center">
      <v-col cols="12" lg="8" md="9" sm="10">
        <v-progress-linear
          class="rounded"
          :value="progress"
          color="#A442FF"
          height="25"
        >
          <template v-slot:default="{ value }">
            <strong class="text-white">{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12" lg="4" md="4" sm="5">
        <v-btn
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-primary
            py-2
            px-6
            me-2
            mb-2
            w-100
          "
          color="#5e72e4"
          @click="openfinishSession()"
          :loading="finishSessionLoading"
          ><v-icon>mr-2 fas fa-check-circle</v-icon> Zakończ wyzwanie</v-btn
        >
      </v-col>
    </v-row>

    <v-row
      v-if="session.challenge.plan[currentDay].trainings_json.length > 1"
      class="mt-2 d-flex justify-center pl-2 pr-2"
    >
      <v-col
        cols="6"
        lg="4"
        md="4"
        sm="5"
        v-for="(training, i) in session.challenge.plan[currentDay]
          .trainings_json"
        :key="training.name"
        :class="trainingIndex == i ? `rounded bg-gradient-light` : 'rounded'"
      >
        <v-img
          :src="training.thumbnail.url"
          elevation="1"
          :style="'max-height: 100px;'"
          contain
          class="rounded thumbnail-static cursor-pointer"
          @click="setTrainingIndexx(i)"
          :ripple="true"
        >
          <template v-slot:placeholder>
            <v-sheet :color="`grey lighten-4`">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
        <h4 class="text-typo text-center">
          {{ `${i + 1}: ${training.name}` }}
        </h4>
      </v-col>
    </v-row>
    <v-row class="mt-2" v-if="session.challenge.plan[currentDay].description">
      <v-col class="pt-0">
        <v-card class="p-1">
          <v-alert dense class="bg-gradient-primary mt-2">
            <span :style="'color: white'">
              {{ session.challenge.plan[currentDay].description }}
            </span>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-divider
      class="mt-2 mb-2 ml-2 mr-2"
      v-if="session.challenge.plan[currentDay].description"
    ></v-divider>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-expansion-panels accordion v-model="currentWeek">
          <v-expansion-panel
            v-for="weekindex in parseInt(session.challenge.weeks)"
            :key="weekindex"
            :class="'bg-gradient-primary text-white'"
          >
            <v-expansion-panel-header>
              {{
                `Tydzień ${weekindex}: ${
                  range((weekindex - 1) * 7, (weekindex - 1) * 7 + 6).filter(
                    (dayindex) =>
                      session.finishedDays.find((ind) => ind == dayindex) !==
                      undefined
                  ).length
                }/7`
              }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-simple-table class="bg-gradient-light">
                <template v-slot:default>
                  <tbody>
                    <tr
                      v-for="(day, dayindex) in session.challenge.plan.slice(
                        (weekindex - 1) * 7,
                        (weekindex - 1) * 7 + 7
                      )"
                      :key="dayindex"
                      :class="`cursor-pointer ${
                        currentDay == (weekindex - 1) * 7 + dayindex
                          ? 'bg-gradient-secondary'
                          : ''
                      }`"
                      :ripple="true"
                      @click="setCurrentDay((weekindex - 1) * 7 + dayindex)"
                    >
                      <td class="text-left pt-2 pb-2">
                        {{
                          `Dzień ${dayindex + 1} ${
                            day.trainings_json.length > 1
                              ? `(${day.trainings_json.length} treningi)`
                              : ""
                          }`
                        }}
                        <v-icon
                          :style="'color: green !important;'"
                          v-if="
                            session.finishedDays.find(
                              (day) => day == (weekindex - 1) * 7 + dayindex
                            ) !== undefined
                          "
                        >
                          fas fa-check-circle
                        </v-icon>
                        <v-btn
                          v-if="
                            currentDay == (weekindex - 1) * 7 + dayindex &&
                            session.finishedDays.find(
                              (day) => day == (weekindex - 1) * 7 + dayindex
                            ) === undefined
                          "
                          :ripple="false"
                          elevation="1"
                          color="#fff"
                          class="
                            font-weight-bolder font-size-hero-medium
                            py-4
                            px-7
                            ms-2
                          "
                          :class="'btn-primary button-primary-gradient'"
                          :style="'text-transform: none;'"
                          rounded
                          @click="openFinishDay((weekindex - 1) * 7 + dayindex)"
                        >
                          <v-icon
                            size="18px"
                            :style="'transform: translateY(1px);'"
                            class="mr-3"
                            >fas fa-check-circle</v-icon
                          >
                          Ukończ dzień
                        </v-btn>
                      </td>
                      <td class="text-left pt-2 pb-2"></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog
      v-model="finishDayDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="finishDayDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Zakończ dzień</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
        </v-toolbar>
        <v-container class="p-4">
          <v-row class="d-flex justify-center montserrat500">
            <v-col cols="10" class="d-flex justify-center">
              <v-radio-group
                v-model="finishDayRadio"
                :style="'wdith: 100% !important; flex: auto;'"
              >
                <v-card
                  v-for="opt in finish_day_options"
                  :key="opt.value"
                  class="pa-2 mb-3 lifestyle-pick"
                  @click="finishDayRadio = opt.value"
                  :style="`background-color: ${
                    finishDayRadio == opt.value
                      ? '#9F38FF'
                      : 'rgba(57, 57, 57, 0.56)'
                  };`"
                >
                  <v-container>
                    <v-row>
                      <v-col cols="1" class="d-flex align-center">
                        <v-radio
                          :label="``"
                          :value="opt.value"
                          color="white"
                          large
                        >
                        </v-radio>
                      </v-col>
                      <v-col cols="11">
                        <h3 :style="'color: white;'">
                          <v-icon
                            v-if="opt.value == 1"
                            :style="'color: white; font-size: 20px;'"
                          >
                            fas fa-trophy
                          </v-icon>
                          {{ opt.label }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-primary
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-1
              mb-2
              w-100
            "
            @click="submitFishday"
            color="#5e72e4"
            ><v-icon>fas fa-check-circle</v-icon>
            <span class="ml-1">Zakończ</span>
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="finishSessionDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on"> Open Dialog </v-btn>
      </template> -->
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="finishSessionDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Zakończ wyzwanie</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false"> Save </v-btn>
            </v-toolbar-items> -->
        </v-toolbar>
        <v-container class="p-4">
          <v-row class="d-flex justify-center montserrat500">
            <v-col cols="10">
              <h3 class="text-typo text-center">
                Nie dokończyłaś/eś jeszcze całego wyzwania!<br> Czy na pewno chcesz
                skończyć wyzwanie teraz?
              </h3>
            </v-col>
          </v-row>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-warning
              bg-gradient-warning
              py-2
              px-6
              me-2
              mt-1
              mb-2
              w-100
            "
            @click="finishSessionDialog = false"
            color="#5e72e4"
            ><v-icon>fas fa-times-circle</v-icon>
            <span class="ml-1">Nie, anuluj</span>
          </v-btn>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-primary
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-1
              mb-2
              w-100
            "
            @click="finishSession()"
            :loading="finishSessionLoading"
            color="#5e72e4"
            ><v-icon>fas fa-check-circle</v-icon>
            <span class="ml-1">Zakończ</span>
          </v-btn>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "MyChallenge",
  data: function () {
    return {
      currentDay: 0,
      currentWeek: 0,
      trainingIndex: 0,
      currentDayIndex: 0,
      progress: 0,
      finishSessionDialog: false,
      finishSessionLoading: false,
      finishDayDialog: false,
      finishDayRadio: 1,
      finish_day_options: [
        {
          value: 1,
          label: "Udało mi się ukończyć treningi",
        },
        {
          value: 0,
          label: "Nie ukończyłam/em treningów",
        },
      ],
    };
  },
  methods: {
    setCurrentDay(dayindex) {
      this.currentDay = dayindex;
      this.currentWeek = Math.floor(dayindex / 7);
      this.trainingIndex = 0;
    },
    setTrainingIndexx(tindex) {
      this.trainingIndex = tindex;
    },
    setProgress() {
      this.progress =
        (this.session.finishedDays.length / this.session.challenge.days) * 100;
    },
    finishDay: function (dayindex) {
      this.$axios
        .post("/api/challenges/finishday", {
          dayindex: dayindex,
          challengeid: this.session.challengeid
        })
        .then((response) => {
          if (response.data.success) {
            this.session.finishedDays.push(dayindex);
            this.setProgress();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openfinishSession: function () {
      if (this.progress > 99) {
        this.finishSession();
      } else {
        this.finishSessionDialog = true;
      }
    },
    finishSession: function () {
      this.finishSessionLoading = true;
      this.$axios
        .post("/api/challenges/finish", {
          challengeid: this.session.challengeid
        })
        .then(async (response) => {
          if (response.data.success) {
            await this.$store.dispatch("fetchUser");
            this.finishSessionLoading = false;
            this.finishSessionDialog = false;
            this.$router.push("/challenges");
          }
        })
        .catch(function (error) {
          this.finishSessionDialog = false;
          this.finishSessionLoading = false;
          console.log(error);
        });
    },
    pushDiary() {
      this.diaryLoading = true;

      this.$axios
        .post("/api/diary/challengeday", {
          resourceid: this.session.challenge.resourceid,
          dayindex: this.currentDayIndex,
          day:this.$getDate()
        })
        .then(async (response) => {
          if (response.data.success) {
            await this.$store.dispatch("fetchUser");
            this.$toast.open({
              message: `<span class="font-weight-bold">Dodano do dzienniczka!</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    range(start, end) {
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    openFinishDay: function (dayindex) {
      if (this.session.challenge.plan[this.currentDay].type !='trainings')
      {
        this.finishDay(dayindex);
        return;
      }
      this.currentDayIndex = dayindex;
      this.finishDayRadio = 1;
      this.finishDayDialog = true;
    },
    submitFishday: function () {
      this.finishDayDialog = false;
      this.finishDay(this.currentDayIndex);
      if (this.finishDayRadio == 1) {
        this.pushDiary();
      }
    },
  },
  mounted() {
    this.setProgress();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    session() {
      let challengeid = this.$route.params.challengeid
      if (!Array.isArray(this.$store.state.userActiveChallengeSession)){
        return false
      }
      let selectedChallenge = this.$store.state.userActiveChallengeSession.find(i => i.challengeid === challengeid)

      return selectedChallenge
    },
  },
};
</script>

<style>
</style>